<template>
	<div class="warning" v-if="computedValue === `ko`">
		<main-header></main-header>
		<h1>서비스 준비중입니다.</h1>
		<Footer />
	</div>
	<div class="warning" v-else-if="computedValue === `en`">
		<main-header></main-header>
		<h1>Service is under preparation.</h1>
		<Footer />
	</div>
</template>

<script>
import MainHeader from '../components/MainHeader.vue';
import Footer from '../components/Footer.vue';
export default {
	components: {
		MainHeader,
		Footer,
	},
	computed: {
		computedValue() {
			return this.$store.state.language;
		},
	},
};
</script>

<style scoped>
h1 {
	text-align: center;
	line-height: 500px;
}
</style>
